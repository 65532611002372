import { createAction, handleActions } from 'redux-actions';

export const FEATURES = {};

const initialState = {};

export const featuresInitRequested = createAction('FEATURES_INIT_REQUESTED');
export const featuresInitSucceeded = createAction('FEATURES_INIT_SUCCEEDED');
export const featuresChanged = createAction('FEATURES_CHANGED', ({ features }) => features);

export default handleActions({
  [featuresInitSucceeded]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [featuresChanged]: (state, { payload }) => ({
    ...state,
    ...Object.entries(payload).reduce((features, [key, { current }]) => ({
      ...features,
      [key]: current,
    }), {}),
  }),
}, initialState);
