import { all, fork } from 'redux-saga/effects';

import { watchChangeRequested } from './changePassword';
import { watchEnable2faRequested } from './enable2fa';
import { watchForgotRequested } from './forgotPassword';
import { watchGenerateCodeKeyRequested } from './generateCodeKey';
import { watchInitialiseFeatures } from './features';
import { watchLoginRequested } from './login';
import { watchResetRequested } from './resetPassword';
import { watchSendCodeRequested } from './sendCode';
import { watchSignUpRequested, watchSignUpResendRequested } from './signUp';
import { watchVerifyCodeRequested } from './verifyCode';

export default function* rootSaga(dispatch, getState) {
  yield all(
    [
      watchChangeRequested,
      watchEnable2faRequested,
      watchForgotRequested,
      watchGenerateCodeKeyRequested,
      watchInitialiseFeatures,
      watchLoginRequested,
      watchResetRequested,
      watchSendCodeRequested,
      watchSignUpRequested,
      watchSignUpResendRequested,
      watchVerifyCodeRequested,
    ].map(fn => fork(fn, dispatch, getState))
  );
}
